import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function urlParser(url) {

  
    // Create a URL object
    let urlObj = new URL(url);

    // Create URLSearchParams object
    let params = new URLSearchParams(urlObj.search);
    let paramObj = {};
    for(var value of params.keys()) {
        paramObj[value] = params.get(value);
    }

    // Add/ Update query parameters
    paramObj.tda= Date.now().toString();
    paramObj.refresh_data=true;

    // Update the URL with the new parameters
    console.log("Objects ",paramObj);
    console.log("String",new URLSearchParams(params).toString())
    urlObj.search = new URLSearchParams(paramObj).toString();

    // Get the updated URL
    let newUrl = urlObj.toString();
    return newUrl;
}

/**
 * Validates and formats the given phone number.
 * @param {string} phoneNumber - The phone number to be validated and formatted.
 * @returns {Object} - Returns an object with a status and the formatted phone number or error message.
 */
export function validateAndFormatPhoneNumber(phoneNumber) {
  const result = {
    isValid: false,
    formattedPhoneNumber: '',
    errorMessage: '',
    countryCode:'',
    nationalPhoneNumber:'',
    phoneNumberURI:'',
    formattedNationalNumber:'',
  };
  phoneNumber = phoneNumber.trim();
  // Check if the number starts with a "+" (international format)
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = `+${phoneNumber}`;
  }
  console.log(phoneNumber)
  if (isValidPhoneNumber(phoneNumber)) {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObject) {
      console.log(phoneNumberObject)
      result.isValid = true;
      result.formattedPhoneNumber = phoneNumberObject.formatInternational();
      result.countryCode = phoneNumberObject.countryCallingCode; // Get the country code (e.g., 1 for USA)
      result.nationalPhoneNumber = phoneNumberObject.nationalNumber; // Get the national number (without the country code)
      result.formattedNationalNumber = formatPhoneNumberWithDashes(result.nationalPhoneNumber);

      result.phoneNumberURI = phoneNumberObject.getURI();
    }
  } else {
    result.errorMessage = 'Invalid phone number';
  }
    console.log(result)
  return result;
}

/**
 * Format a national phone number with dashes (e.g., "6105630400" -> "610-563-0400").
 * @param {string} nationalNumber - The national phone number to format.
 * @returns {string} - The formatted phone number with dashes.
 */
function formatPhoneNumberWithDashes(nationalNumber) {
  // Use a regular expression to split the national number into parts
  const match = nationalNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // Format as xxx-xxx-xxxx
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  
  return nationalNumber; // Return the original number if it can't be matched
}