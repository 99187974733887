<template>
    <div class="container">
      <input v-model="phoneNumber" id="output" name="phoneNumber" />
      <div class="row">
        <div class="  digit" @click="addDigit('1')">1</div>
        <div class="  digit" @click="addDigit('2')">
          2
          <div class="sub">ABC</div>
        </div>
        <div class="  digit" @click="addDigit('3')">
          3
          <div class="sub">DEF</div>
        </div>
      </div>
      <div class="row">
        <div class="  digit" @click="addDigit('4')">
          4
          <div class="sub">GHI</div>
        </div>
        <div class="  digit" @click="addDigit('5')">
          5
          <div class="sub">JKL</div>
        </div>
        <div class=" digit" @click="addDigit('6')">
          6
          <div class="sub">MNO</div>
        </div>
      </div>
      <div class="row">
        <div class="  digit" @click="addDigit('7')">
          7
          <div class="sub">PQRS</div>
        </div>
        <div class="   digit" @click="addDigit('8')">
          8
          <div class="sub">TUV</div>
        </div>
        <div class="  digit" @click="addDigit('9')">
          9
          <div class="sub">WXYZ</div>
        </div>
      </div>
      <div class="row">
        <div class=" digit" @click="addDigit('*')">+</div>
        <div class="  digit" @click="addDigit('0')">0</div>
        <div class=" digit" @click="addDigit('#')">#</div>
      </div>
      <div class="row">
        <div class=" digit " ><i class="fa fa-star-o " aria-hidden="true"></i></div>
        <div class="  digit" id="call" @click="makeCall"><i class="fa fa-phone" aria-hidden="true"></i></div>
        <div class=" digit " @click="removeLastDigit" ><i class="fa fa-long-arrow-left" aria-hidden="true" ></i></div>
      </div>
      <br />
    </div>
  </template>
  
  <script>
  export default {
    props: ['phoneNumber'],
    data() {
      return {
        output: '',
      };
    },

    methods: {
      addDigit(digit) {
        if (this.phoneNumber.length < 15) {
          this.phoneNumber += digit;
        }
      },
      removeLastDigit() {
        this.phoneNumber = this.phoneNumber.slice(0, -1);
      },
      makeCall() {
        console.log('Making call to:', this.phoneNumber);
      },
    },
  };
  </script>
  
  <style scoped>
  .row {
    margin: 0 auto;
    width: 100%;
    clear: both;
    text-align: center;
    font-family: 'Exo';
  }
  
  .digit,
  .dig {
    float: left;
    width: 33.333333%;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .sub {
    font-size: 0.8rem;
    color: grey;
  }
  
  .container {
    background-color: white;
    /*width: 320px; */
    padding: 20px;
    margin: 30px auto;
    /* height: 420px; */
    text-align: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  
  #output {
    font-family: 'Exo';
    font-size: 2rem;
    height: 60px;
    font-weight: bold;
    color: #1976d2;
    width: 100%;
    border: none;
  }

  .action-button{
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;
    background-color:transparent;
    color:aliceblue;
  }
  .action-button :hover{
    background-color: #676767;
    color:#e6e6e6;
    border-color: aliceblue;
  }
  
  #call {
    display: inline-block;
    background-color: #66bb6a;
    color: white;
    border-radius: 4px;
    float: left;
    cursor: pointer;
  }
  
  .botrow {
    margin: 0 auto;
    width: 100%;
    clear: both;
    text-align: center;
    font-family: 'Exo';
  }
  
  .digit:active,
  .dig:active {
    background-color: #e6e6e6;
  }
  
  #call:hover {
    background-color: #81c784;
  }
  
  .dig {
    float: left;
    padding: 10px 20px;
    margin: 10px;
    width: 30px;
    cursor: pointer;
  }
  </style>
  